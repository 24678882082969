import { ApiCall } from "../apiBase";

class UploadService {
  /*********** get All Answer options include in column ********/
  async getAllAnswerOptions(datafileKey, columnNumber, datarecordStartRow, sheetName) {
    let answerOptions = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllAnswerOptions($datafileInput: String!, $columnNumberInput: Int! , $datarecordStartRowInput: Int!, $sheetName: String!){
            getAllAnswerOptions(datafileKey: $datafileInput, columnNumber: $columnNumberInput, datarecordStartRow: $datarecordStartRowInput, sheetName: $sheetName)
          }
        `,
        variables: {
          datafileInput: datafileKey,
          columnNumberInput: columnNumber,
          datarecordStartRowInput: datarecordStartRow,
          sheetName
        }
      }
    });

    if (result?.data?.data?.getAllAnswerOptions && !result?.data?.errors) {
      answerOptions = result.data.data.getAllAnswerOptions;
    } else {
      answerOptions = result?.data?.errors[0]?.extensions?.code;
    }

    return answerOptions;
  }

  async getAllFilterOptions(datafileKey, columnNumber, datarecordStartRow) {
    let filterOptions = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllFilterOptions($datafileInput: String!, $columnNumberInput: Int!, $datarecordStartRowInput: Int!){
            getAllFilterOptions(datafileKey: $datafileInput, columnNumber: $columnNumberInput, datarecordStartRow: $datarecordStartRowInput)
          }
        `,
        variables: {
          datafileInput: datafileKey,
          columnNumberInput: columnNumber,
          datarecordStartRowInput: datarecordStartRow
        }
      }
    });

    if (result?.data?.data?.getAllFilterOptions && !result?.data?.errors) {
      filterOptions = result.data.data.getAllFilterOptions;
    } else {
      filterOptions = result?.data?.errors[0]?.extensions?.code;
    }

    return filterOptions;
  }

  /************* Upload Datafile ***********************/
  async uploadDatafile(datafileObjectInput, certificateLevel) {
    const res = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation addDatafile($input: DatafileInput!, $certificateLevel: String) {
            addDatafile(datafile: $input, certificateLevel: $certificateLevel) {
              status
              message
            }
          }
        `,
        variables: {
          input: datafileObjectInput,
          certificateLevel
        }
      }
    });
    return res;
  }

  /********* get Number of Credits *********/
  async getCreditAmount(qualitySocreCount, creditMetadata, datafileKey) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getNumberOfCreditsForDatafile($qualityScoresInput: qualityScoresCount!, $creditMetadata: creditMetadataInput!, $datafileKey: String!){
            getNumberOfCreditsForDatafile(qualityScores: $qualityScoresInput, creditMetadata: $creditMetadata, datafileKey: $datafileKey) {
              maximumCreditAmount
              difference
              qualityScoreCredits {
                qualityScore
                numberOfDataPoints
                credits
               }
            }
          }
        `,
        variables: {
          qualityScoresInput: qualitySocreCount,
          creditMetadata,
          datafileKey
        }
      }
    });

    if (
      result?.data?.data?.getNumberOfCreditsForDatafile &&
      !result?.data?.errors
    ) {
      return result.data.data.getNumberOfCreditsForDatafile;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }
}

export default new UploadService();
